import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PostCard from '../../../components/post-card/post-card';
import Pagination from '../../../components/pagination/pagination';
import BlogPostsWrapper from './style';

type PostsProps = {};

const Posts: React.FunctionComponent<PostsProps> = () => {
  const Data = useStaticQuery(graphql`
    query {
      allSitePage(filter: { path: { eq: "/page/1" } }) {
        nodes {
          context {
            numPages
            currentPage
          }
        }
      }
      allContentfulBlogPost(
        sort: { fields: createdAt, order: DESC }
        limit: 10
      ) {
        totalCount
        edges {
          node {
            slug
            excerpt {
              excerpt
            }
            createdAt(formatString: "DD [<span>] MMM [</span>]")
            title
            tags {
              slug
              name
            }
            featured {
              fluid(maxWidth: 1170, maxHeight: 690, quality: 90) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);

  const Posts = Data.allContentfulBlogPost.edges;
  const TotalPage = Data.allSitePage.nodes[0].context.numPages;
  const CurrentPage = Data.allSitePage.nodes[0].context.currentPage;

  return (
    <BlogPostsWrapper>
      {Posts.map(({ node }: any) => {
        const title = node.title || node.slug;
        return (
          <PostCard
            key={node.slug}
            title={title}
            image={node.featured == null ? null : node.featured.fluid}
            url={`/${node.slug}`}
            description={node.excerpt.excerpt}
            date={node.createdAt}
            tags={node.tags}
          />
        );
      })}

      {TotalPage >> 1 ? (
        <Pagination
          nextLink="/page/2"
          currentPage={CurrentPage}
          totalPage={TotalPage}
        />
      ) : (
        ''
      )}
    </BlogPostsWrapper>
  );
};

export default Posts;
