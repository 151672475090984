import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import SocialProfile from '../../../components/social-profile/social-profile';
import { IntroWrapper, IntroImage, IntroTitle, Desciption } from './style';
import { AiOutlineMail, AiOutlinePhone } from 'react-icons/ai';

type IntroProps = {};

const SocialLinks = [
  {
    icon: <AiOutlineMail />,
    url: 'mailto:sawasdee@deeboon.com',
    tooltip: 'อีเมล',
  },
  {
    icon: <AiOutlinePhone />,
    url: 'tel:0917656679',
    tooltip: 'โทรศัพท์',
  },
];

const Intro: React.FunctionComponent<IntroProps> = () => {
  const { contentHome } = useStaticQuery(
    graphql`
      query {
        contentHome: allContentfulHomePage {
          edges {
            node {
              introImage {
                fluid(maxWidth: 210, maxHeight: 210, quality: 90) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
              introTitle
              introDescription {
                introDescription
              }
            }
          }
        }
      }
    `
  );

  const { introImage, introTitle, introDescription } =
    contentHome.edges[0].node;

  return (
    <IntroWrapper>
      <IntroImage>
        <Image fluid={introImage.fluid} alt="author" />
      </IntroImage>
      <IntroTitle dangerouslySetInnerHTML={{ __html: introTitle }} />
      <Desciption
        dangerouslySetInnerHTML={{ __html: introDescription.introDescription }}
      />
      <SocialProfile items={SocialLinks} />
    </IntroWrapper>
  );
};

export default Intro;
